import { store } from "../store";

export const routes = [
  {
    path: "/",
    component: () => import("../pages/Layout.vue"),
    children: [
      {
        path: "/",
        name: "Index",
        component: () => import("../pages/Home.vue"),
        beforeEnter: (to, from, next) => {
          next();
        },
      },
      {
        path: "/home/:page?",
        name: "Home.page",
        onePage: true,
        component: () => import("../pages/Home.vue"),
        beforeEnter: (to, from, next) => {
          next();
        },
      },
      {
        path: "/politica-privacidade",
        name: "PoliticaPrivacidade",
        component: () =>
          import("../pages/politicaPrivacidade/PoliticaDePrivacidade.vue"),
        beforeEnter: (to, from, next) => {
          if (!localStorage.getItem("access_token")) {
            store.dispatch("DELETE_USER");
          }
          next();
        },
      },
      {
        path: "/:pathMatch(.*)*",
        name: "NotFound",
        component: () => import("../pages/NotFound.vue"),
      },
    ],
  },
];
