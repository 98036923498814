import Vue from "vue";
import { createRouter, createWebHistory } from "vue-router";

// Vue.use(createRouter, createWebHashHistory);

const { routes } = require("./routes.js");

const publicPath = "/";
const router = createRouter({
  history: createWebHistory(publicPath),
  base: publicPath,
  routes,
});

export default router;
